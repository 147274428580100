import React from 'react';
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component"; 
import "react-vertical-timeline-component/style.min.css";
import SchoolIcon from "@material-ui/icons/School";
import WorkIcon from "@material-ui/icons/Work";
import "../styles/Experience.css";

function Experience() {
  return (
    <div className='experience'>
      <VerticalTimeline lineColor='#3e497a'>
        
        {/* Work Experience */}
        <VerticalTimelineElement
            className='vertical-timeline-element--education'
            date="May 2023 - Present"
            iconStyle={{background: "#e9d35b", color: "#fff"}}
            icon={<WorkIcon/>}
        >
          <h3 className='vertical-timeline-element-title'>
            A&D Stoneworks
          </h3>
          <ul>
            <li> Laying natural or man-made stone on houses, patios, pools and fireplaces</li>
            <li>Mixing mortar and forming/pouring concrete</li>
          </ul>
        </VerticalTimelineElement>

        {/* University Info */}
        <VerticalTimelineElement
            className='vertical-timeline-element--education'
            date="Sept 2019 - Apr 2023"
            iconStyle={{background: "#3e497a", color: "#fff"}}
            icon={<SchoolIcon/>}
        >
          <h3 className='vertical-timeline-element-title'>
            McMaster University
          </h3>
          <ul>
            <li>B.A.Sc. Honours, Computer Science</li>
            <li>GPA: 10.8/12.0, Dean's Honour Roll</li>
          </ul>
        </VerticalTimelineElement>

        {/* Work Experience */}
        <VerticalTimelineElement
            className='vertical-timeline-element--education'
            date="Jun 2022 - Aug 2022"
            iconStyle={{background: "#e9d35b", color: "#fff"}}
            icon={<WorkIcon/>}
        >
          <h3 className='vertical-timeline-element-title'>
            A&D Stoneworks
          </h3>
          <ul>
            <li> Laying natural or man-made stone on houses, patios, pools and fireplaces</li>
            <li>Mixing mortar and forming/pouring concrete</li>
          </ul>
        </VerticalTimelineElement>

        <VerticalTimelineElement
            className='vertical-timeline-element--education'
            date="May 2020 - Aug 2021"
            iconStyle={{background: "#e9d35b", color: "#fff"}}
            icon={<WorkIcon/>}
        >
          <h3 className='vertical-timeline-element-title'>
            Sobeys
          </h3>
          <ul>
            <li>Delegated tasks between 2-8 cashiers to keep the store
                clean and front end running smoothly</li>
            <li>Assisted customers with returns and answered questions
                over the phone or in person</li>
          </ul>
        </VerticalTimelineElement>

        {/* High School */}
        <VerticalTimelineElement
          className='vertical-timeline-element--education'
          date="Sept 2015 - Jun 2019"
          iconStyle={{background: "#3e497a", color: "#fff"}}
          icon={<SchoolIcon/>}
        >
          <h3 className='vertical-timeline-element-title'>
            South Huron District High School
          </h3>
          <ul>
            <li>High School Diploma</li>
            <li>Schulich Scholarship Nominee</li>
          </ul>
        </VerticalTimelineElement>
        
      </VerticalTimeline>
    </div>
  )
}

export default Experience;