import React from "react";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import EmailIcon from "@material-ui/icons/Email";
import GitHubIcon from "@material-ui/icons/GitHub";
import "../styles/Home.css";

function Home() {
  return (
    <div className="home">
      <div className="about">
        <h2> Hi, My Name is Evan</h2>
        <div className="prompt">
          <p>A computer science graduate with a passion for learning and creating.</p>
          <a href="https://www.linkedin.com/in/evan-desjardine/"><LinkedInIcon /></a>
          <a href="mailto:evan.desjardine@gmail.com"><EmailIcon /></a>
          <a href="https://github.com/EvanDesj"><GitHubIcon /> </a>
        </div>
      </div>

      <div className="skills"> 
        <h1>Skills</h1>
        <ol className="list">
          <li className="item">
            <h2>Languages</h2>
            <span>
              Python, Java, C, C++, Haskell, Bash, NASM, SQL, HTML, CSS, Javascript
            </span>
          </li>
          <li className="item">
            <h2>Tools/Frameworks</h2>
            <span>
              Git, ReactJS, Django, OpenGL, LaTeX, Docker
            </span>
          </li>
        </ol>
      </div>
    </div>
  );
}

export default Home;