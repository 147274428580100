import MinesweeperImg from "../assets/Minesweeper.png";
import MazeGameImg from "../assets/3D-Maze-Game.png";
import ParticleSystem3DImg from "../assets/3D-Particle-System.png";
import ParticleSystem2DImg from "../assets/2D-Particle-System.png";
import SocialMediaImg from "../assets/Social-Media-Proj.png";

export const ProjectList = [
    {
        name: "Minesweeper",
        image: MinesweeperImg,
        skills: "Java with Netbeans GUI",
        link: "https://github.com/EvanDesj/Minesweeper-Project"
    },
    {
        name: "3D Maze Game",
        image: MazeGameImg,
        skills: "C++ and OpenGL",
        link: "https://github.com/EvanDesj/3D-Maze-Game"
    },
    {
        name: "3D Particle System",
        image: ParticleSystem3DImg,
        skills: "C++ and OpenGL",
        link: "https://github.com/EvanDesj/Particle-System-Project"
    },
    {
        name: "2D Particle System",
        image: ParticleSystem2DImg,
        skills: "C++ and OpenGL",
        link: "https://github.com/EvanDesj/2D-Particles-Project"
    },
    {
        name: "Social Media Platform",
        image: SocialMediaImg,
        skills: "HTML, CSS, Javascript, Python, Django",
        link: "https://github.com/EvanDesj/CS1XA3/tree/master/Project03"
    },
]