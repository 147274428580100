import React from 'react'
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import EmailIcon from "@material-ui/icons/Email";
import GitHubIcon from "@material-ui/icons/GitHub";
import "../styles/Footer.css";

function Footer() {
  return (
    <div className="footer">
        <div className="socialMedia">
          <a href="https://www.linkedin.com/in/evan-desjardine/"><LinkedInIcon /></a>
          <a href="mailto:evan.desjardine@gmail.com"><EmailIcon /></a>
          <a href="https://github.com/EvanDesj"><GitHubIcon /> </a>
        </div>
        <p>Inspired by <a href="https://www.youtube.com/watch?v=x7mwVn2z3Sk" target="_blank" rel="noreferrer">PedroTech</a></p>
        <p> &copy; 2023 evan.desjardine.ca</p>
    </div>
  )
}

export default Footer;