import React from 'react';
import { useParams } from 'react-router-dom';
import { ProjectList } from "../helpers/ProjectList";
import GitHubIcon from "@material-ui/icons/GitHub";
import "../styles/ProjectDisplay.css";

function ProjectDisplay() {
  const { id } = useParams();
  const project = ProjectList[id];
  return (
    <div className='project'>
        <h1>{project.name}</h1>
        <img src={project.image} alt="Project Pic"/>
        <div className="skills">
            <p><b>Skills:</b></p>
            <p>{project.skills}</p>
        </div>
        <div className="viewCode">
            <p><b>View the Code:</b></p>
            <a href={project.link}><GitHubIcon/></a>
        </div>
    </div>
  )
}

export default ProjectDisplay
